import './App.css';

function Drink(props) {
  return (
    <div className="container mx-auto py-6">
      <img src={props.logo} className="drink-logo" alt="logo" />
      <p className="drink-title font-sans font-semibold text-lg">
        {props.type}
      </p>
      <p className="drink-ingredients italic font-sans font-light text-sm">
        {props.ingredients}
      </p>
    </div>
  );
}

export default Drink;

import martini from './images/martini.png';
import cocktail from './images/cocktail.png';
import margarita from './images/margarita.png';
import mule from './images/mule.png';
import cosmo from './images/cosmo.png';
import whiteclaw from './images/whiteclaw.png';
import dog from './images/dog.svg';
import wine from './images/wine.png';
import purplecocktail from './images/purple-cocktail.png';
import './App.css';
import Drink from './Drink';

function App() {
  return (
    <div className="App">
      <div className="flex flex-row heading">
        <div className="basis-1/5 grid grid-rows-1 grid-flow-col gap-2">
          {/*<h1 className="title text-4xl font-light font-sans">*/}
          {/*  The Dog Pound*/}
          {/*</h1>*/}
          <img src={dog} className="drink-logo" alt="logo" />
        </div>
      </div>
      <div className="columns-1 container mx-auto py-4">
        <Drink
          type="Espresso Martini"
          logo={martini}
          ingredients="Vodka | Coffee liqueur | Espresso | Simple syrup"
        />
        <Drink
          type="Bees Knees"
          logo={cocktail}
          ingredients="Gin | Honey syrup | Lemon juice"
        />
        <Drink
          type="Gimlet"
          logo={purplecocktail}
          ingredients="Gin | Lime juice | Simple syrup"
        />
        <Drink
          type="French 75"
          logo={cosmo}
          ingredients="Gin | Lemon juice | Simple syrup | Champagne"
        />
        <Drink
          type="Margarita"
          logo={margarita}
          ingredients="Tequila | Lime juice | Agave"
        />
        <Drink
          type="Moscow Mule"
          logo={mule}
          ingredients="Vodka | Ginger beer | Lime juice"
        />
        <Drink
          type="Whiteclaw"
          logo={whiteclaw}
          ingredients="Purified carbonated water | alcohol | natural flavors | cane sugar | citric acid | sodium citrate"
        />
        <Drink
          type="Wine"
          logo={wine}
          ingredients="Grapes"
        />
      </div>
    </div>
  );
}

export default App;
